









































































































































import { ESearch, FacetsFilters, Pagination, Scrollbar, SearchTerms, SvgImage, TwSelect } from '@/app/components';
import { useFacetsFilters, useSearch } from '@/app/composable';
import { AccessLevel } from '@/modules/access-policy/constants/access-levels.constants';
import { StatusCode } from '@/modules/asset/constants';
import { SearchAPI } from '@/modules/search/api';
import { FilterIcon } from '@vue-hero-icons/solid';
import { computed, defineComponent, onBeforeUnmount, ref } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import { ContractsAPI } from '../api';
import { AssetCard, AssetDetails } from '../components';
import { useMarketplaceFacets } from '../composable';
import { MarketplaceSortingOption } from '../constants';

export default defineComponent({
    name: 'Marketplace',
    metaInfo: {
        title: 'Marketplace',
    },
    components: {
        OrbitSpinner,
        AssetCard,
        AssetDetails,
        SvgImage,
        FilterIcon,
        Pagination,
        TwSelect,
        Scrollbar,
        ESearch,
        FacetsFilters,
        SearchTerms,
    },
    setup(props, { root }) {
        const searchableFields = [
            {
                label: 'title',
                key: 'name.keyword',
                textColorClasses: 'text-primary-700',
                backgroundClasses: 'bg-primary-700',
                highlightClasses: 'bg-primary-300',
                widthClasses: 'pl-16 ml-2',
            },
            {
                label: 'description',
                key: 'description.keyword',
                textColorClasses: 'text-teal-700',
                backgroundClasses: 'bg-teal-700',
                highlightClasses: 'bg-teal-300',
                widthClasses: 'pl-28 ml-2',
            },
            {
                label: 'tags',
                key: 'metadata.general.tags.keyword',
                textColorClasses: 'text-purple-700',
                backgroundClasses: 'bg-purple-700',
                highlightClasses: 'bg-purple-300',
                widthClasses: 'pl-20 ',
            },
        ];
        const openFilters = ref<boolean>(true);
        const pageSize = 48;
        const facets = ref<Record<string, { value: string; count: number; selected: boolean }[]>>({});
        const assets = ref<any>([]);
        const totalResults = ref<number>(0);
        const selectedAsset = ref<any>(null);
        const activePendingContracts = ref<any[]>([]);

        const queryParams = computed(() => JSON.stringify(root.$route.query));

        const setSearchQuery = () => {
            searchQuery.value.facets = { ...reducedMarketplaceFacets, status: [StatusCode.Available] };
            searchQuery.value.filters = {
                isLocal: false,
                accessLevel: [AccessLevel.Public, AccessLevel.SelectiveSharing],
            };
        };

        const search = () => {
            setCommonSearchQueryValues();

            searchQuery.value.query.settings = query.value.settings;

            setQueryParams();

            exec(SearchAPI.marketplace(searchQuery.value)).then((res: any) => {
                assets.value = res.data?.results;
                facets.value = res.data?.facets;
                totalResults.value = res.data?.total;
            });
        };

        const {
            exec,
            loading,
            error,
            get,
            page,
            sortBy,
            query,
            searchQuery,
            updateCurrentPage,
            facetChanged,
            sortByChanged,
            clearFilters,
            setCommonSearchQueryValues,
            setQueryParams,
            unsubscribeSearch,
            sortingFields,
        } = useSearch(root, 'marketplace', pageSize, MarketplaceSortingOption, '', setSearchQuery, search);

        onBeforeUnmount(() => {
            unsubscribeSearch.value ? unsubscribeSearch.value() : undefined;
        });

        const { marketplaceFacets, reducedMarketplaceFacets } = useMarketplaceFacets(get);

        const { calculateSearchedFacets } = useFacetsFilters();

        const currentSearchedFacetsFilters = computed(() =>
            calculateSearchedFacets(searchQuery.value.facets, marketplaceFacets.value),
        );

        const getActivePendingContracts = () => {
            exec(ContractsAPI.getActivePending()).then((res: any) => {
                activePendingContracts.value = res.data;
            });
        };

        setSearchQuery();
        search();
        getActivePendingContracts();

        return {
            loading,
            error,
            assets,
            pageSize,
            totalResults,
            updateCurrentPage,
            openFilters,
            facets,
            facetChanged,
            searchQuery,
            sortingFields,
            sortByChanged,
            query,
            sortBy,
            page,
            clearFilters,
            queryParams,
            marketplaceFacets,
            currentSearchedFacetsFilters,
            searchableFields,
            selectedAsset,
            activePendingContracts,
            getActivePendingContracts,
        };
    },
});
