


















































































































































































import {
    ESearch,
    FacetsFilters,
    Pagination,
    Scrollbar,
    SearchTerms,
    SvgImage,
    TailwindTableTabs,
    TwSelect,
} from '@/app/components';
import { useFacetsFilters, useSearch } from '@/app/composable';
import { UserRoles } from '@/app/constants';
import store from '@/app/store';
import { SearchAPI } from '@/modules/search/api';
import { FilterIcon } from '@vue-hero-icons/solid';
import { computed, defineComponent, onBeforeUnmount, ref } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import * as R from 'ramda';
import { ContractOverview, WalletModal } from '../components';
import { useContractFacets, useContractFilters } from '../composable';
import { ContractSortingOption, WalletAction } from '../constants';

export default defineComponent({
    name: 'Contracts',
    metaInfo: {
        title: 'Contracts',
    },
    components: {
        OrbitSpinner,
        ContractOverview,
        SvgImage,
        TailwindTableTabs,
        FilterIcon,
        Pagination,
        TwSelect,
        Scrollbar,
        ESearch,
        FacetsFilters,
        SearchTerms,
        WalletModal,
    },
    setup(props, { root }) {
        const pageSize = 40;

        const tabs = ref<{ key: string; label: string }[]>([
            { key: 'all', label: 'All' },
            { key: 'provider', label: 'As Provider' },
            { key: 'consumer', label: 'As Recipient' },
        ]);
        const openFilters = ref<boolean>(true);
        const facets = ref<Record<string, { value: string; count: number; selected: boolean }[]>>({});
        const contracts = ref<any>([]);
        const rejectedItems = ref<number>(0);
        const totalResults = ref<number>(0);
        const showWalletModal = ref<WalletAction | null>(null);

        const queryParams = computed(() => JSON.stringify(root.$route.query));
        const user = computed(() => store.state.auth.user);
        const isBlockchainEnabled = ref<boolean>(!!process.env.VUE_APP_ETH_NODE);
        const ethNetwork = computed(() => store.state.sharing.network);
        const hasWallet = computed(() => store.getters.organisation.hasWallet);
        const isLegalRepresentative = computed(() => store.getters.auth.hasRole(UserRoles.LegalRepresentative));

        const setSearchQuery = () => {
            searchQuery.value.facets = reducedContractFacets;
            searchQuery.value.filters = reducedContractFilters;
        };

        const search = () => {
            setCommonSearchQueryValues();

            searchQuery.value.query.settings = query.value.settings;
            searchQuery.value.settings = {};

            switch (tab.value) {
                case 'all':
                    searchQuery.value.settings.organisationId = user.value.organisationId;
                    break;
                case 'provider':
                    searchQuery.value.settings.providerOrgId = user.value.organisationId;
                    break;
                case 'consumer':
                    searchQuery.value.settings.consumerOrgId = user.value.organisationId;
                    break;
                default: // do nothing
            }

            setQueryParams();

            exec(SearchAPI.contracts(searchQuery.value)).then((res: any) => {
                contracts.value = res.data?.results;
                facets.value = res.data?.facets;
                rejectedItems.value = res.data?.accessControl?.rejectedItems || 0;
                totalResults.value = res.data?.total;
            });
        };

        const {
            exec,
            loading,
            error,
            get,
            page,
            sortBy,
            tab,
            query,
            searchQuery,
            setTab,
            updateCurrentPage,
            facetChanged,
            filterChanged,
            sortByChanged,
            clearFilters,
            setCommonSearchQueryValues,
            setQueryParams,
            unsubscribeSearch,
            sortingFields,
        } = useSearch(root, 'contracts', pageSize, ContractSortingOption, 'all', setSearchQuery, search);

        onBeforeUnmount(() => {
            unsubscribeSearch.value ? unsubscribeSearch.value() : undefined;
        });

        const { contractFacets, reducedContractFacets } = useContractFacets(get);

        const { contractFilters, reducedContractFilters } = useContractFilters(get);

        const { calculateSearchedFacets, calculateSearchedFilters } = useFacetsFilters();

        const currentSearchedFacetsFilters = computed(() =>
            calculateSearchedFacets(searchQuery.value.facets, contractFacets.value).concat(
                calculateSearchedFilters(searchQuery.value.filters, contractFilters.value),
            ),
        );

        setSearchQuery();
        search();

        const reload = () => {
            search();
        };

        return {
            loading,
            error,
            contracts,
            tab,
            tabs,
            setTab,
            pageSize,
            totalResults,
            updateCurrentPage,
            openFilters,
            facets,
            facetChanged,
            searchQuery,
            sortingFields,
            sortByChanged,
            query,
            sortBy,
            page,
            rejectedItems,
            filterChanged,
            clearFilters,
            queryParams,
            contractFilters,
            contractFacets,
            currentSearchedFacetsFilters,
            R,
            isBlockchainEnabled,
            ethNetwork,
            hasWallet,
            isLegalRepresentative,
            showWalletModal,
            WalletAction,
            search,
            reload,
        };
    },
});
