




































































































import { AlertBanner } from '@/app/components';
import store from '@/app/store';
import { AssetsAPI } from '@/modules/asset/api';
import { useWishList } from '@/modules/asset/composable/wish-list';
import { ContractItem, ContractSteps, MultipleContractsActions } from '@/modules/sharing/components';
import { useAxios } from '@/app/composable';
import { ChevronLeftIcon } from '@vue-hero-icons/outline';
import { Ref, computed, defineComponent, ref } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import * as R from 'ramda';
import { ValidationObserver } from 'vee-validate';
import { ContractsAPI } from '../api';
import { useCart, useEthers } from '../composable';
import { ContractAction, ContractDurationType, ContractStatus, Currency, ReimbursementMethod } from '../constants';
import { Bundle } from '../types';
import { Contract } from '../types/contract.interface';

export default defineComponent({
    name: 'Contract',
    metaInfo: {
        title: 'Contract',
    },
    components: {
        ValidationObserver,
        OrbitSpinner,
        AlertBanner,
        ContractSteps,
        ContractItem,
        MultipleContractsActions,
        ChevronLeftIcon,
    },
    props: {
        type: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
    },
    setup(props, { root }) {
        const { exec, loading } = useAxios(true);
        const { transformToEthers } = useEthers();
        const { cart, clearCart, removeFromCart } = useCart();

        const contentRef = ref<any>(null);
        const expandedIdx = ref<number>(-1);
        const contracts = ref<Contract[]>([]);
        const activePendingContracts = ref<number[]>([]);
        const user = computed(() => store.state.auth.user);
        const bundle = ref<Bundle | null>(null);
        const totalCosts = ref<any>({});
        const currentStep = ref<number>(0);
        const pendingUpdatingContracts = ref<string[]>([]);
        const contractAction = ref<ContractAction | null>(null);
        const signedContracts = ref<string[]>([]);

        const creatingContract: Ref<boolean> = ref<boolean>(props.type === 'create');
        const isNewContract: Ref<boolean> = ref<boolean>(props.type === 'create');
        const isBundle: Ref<boolean> = ref<boolean>(props.id === 'bundle' || props.type === 'bundle');
        const isConsumer = ref<boolean>(false);
        const isLegalRepresentative = ref<boolean>(user.value.roles.includes('legal-representative'));
        const allProvidersHaveAnEthaddress = ref<boolean>(true);
        const allContractsSigned = ref<boolean>(false);

        const isBlockchainEnabled = ref<boolean>(!!process.env.VUE_APP_ETH_NODE);

        const emptyContract = ref<Contract>({
            id: '',
            duration: { type: ContractDurationType.Forever, number: null },
            terms: null,
            metadata: {
                organisationId: user.value.organisationId,
                pricing: {
                    cost: 0,
                    vat: null,
                    currency: Currency.Euro,
                    reimbursementMethod: null,
                },
                license: {
                    expectedUsage: [],
                    offlineRetention: [],
                    expectedUpdates: null,
                },
            },
            message: null,
            providerOrg: {
                id: null,
                businessName: null,
                ethaddress: null,
            },
            consumerOrg: {
                id: user.value.organisationId,
                businessName: null,
                ethaddress: null,
            },
            asset: null,
            assetId: null,
            barteringAsset: null,
            barteringAssetId: null,
            fields: [],
            status: null,
            providerOrgId: null,
            consumerOrgId: user.value.organisationId,
            transactions: [],
        });

        const multiple = computed(() => contracts.value.length > 1);
        const ethNetwork = computed(() => store.state.sharing.network);
        const hasWallet = computed(() => store.getters.organisation.hasWallet);

        const configurationErrors = computed(() => {
            const errors = {};
            for (let i = 0; i < contracts.value.length; i++) {
                if (contracts.value[i].asset?.id) {
                    const assetId = contracts.value[i].asset?.id;
                    errors[assetId as number] =
                        contracts.value[i].asset?.assetTypeId !== 2 &&
                        contracts.value[i].asset?.assetTypeId !== 3 &&
                        contracts.value[i].fields.length === 0 &&
                        contracts.value[i].asset?.structure.type !== 'other';
                }
            }
            return errors;
        });

        const pageTitle = computed(() => {
            if (multiple.value) {
                if (creatingContract.value) {
                    if (contractAction.value === ContractAction.Reactivate)
                        return 'Create a Contract Request to Reactivate Contract';
                    if (contractAction.value === ContractAction.Extend)
                        return 'Create a Contract Request to Extend Contract';
                    if (isBundle.value)
                        return isNewContract.value ? 'Create a Bundle Contract Request' : 'Bundle Contract Request';
                    return isNewContract.value
                        ? 'Create a Contract Request for each Asset'
                        : 'Contract Request for each Asset';
                }
                if (isBundle.value) return 'Bundle Contract';
            }
            return null;
        });

        const prepareContract = (data: Contract) => {
            const contract = R.clone(data);
            if (
                contract.status === ContractStatus.Request &&
                !contract.updatingContractId &&
                contract.asset?.metadata
            ) {
                // Set license metadata
                const assetDistribution = contract.asset.metadata.distribution;
                if (assetDistribution) contract.metadata.license.expectedUpdates = assetDistribution.accrualPeriodicity;

                const assetLicense = contract.asset.metadata.license;
                if (assetLicense) {
                    contract.metadata.license.expectedUsage = assetLicense.expectedUsage;
                    contract.metadata.license.offlineRetention = assetLicense.offlineRetention;
                }

                // Set pricing metadata
                const assetPricing = contract.asset.metadata.pricing;
                if (assetPricing && assetPricing.cost) {
                    if (assetPricing.calculationScheme === 'Fixed per Data Asset') {
                        contract.metadata.pricing.cost = assetPricing.cost;
                        contract.metadata.pricing.currency = assetPricing.currency;
                    } else if (assetPricing.calculationScheme === 'Fixed per Row' && contract.asset.volume.value) {
                        contract.metadata.pricing.cost = assetPricing.cost * contract.asset.volume.value;
                        contract.metadata.pricing.currency = assetPricing.currency;
                    }
                }
                if (contract.bundleId) {
                    contract.metadata.pricing.reimbursementMethod = ReimbursementMethod.Online;
                    contract.metadata.pricing.currency = Currency.Ether;
                }
                // Set cost in ethers
                if (
                    contract.metadata.pricing.reimbursementMethod === ReimbursementMethod.Online &&
                    contract.metadata.pricing.cost
                ) {
                    contract.metadata.pricing.cost = transformToEthers(
                        contract.metadata.pricing.cost,
                        contract.metadata.pricing.currency as Currency,
                    );
                }
            }
            return contract;
        };

        const back = () => {
            if (window.history.length > 0) root.$router.go(-1);
            else root.$router.push({ name: 'contracts' });
        };

        const getBundle = () => {
            exec(ContractsAPI.getBundle(props.id))
                .then((res: any) => {
                    bundle.value = res.data as Bundle;
                    isConsumer.value = res.data.consumerOrgId === user.value.organisationId;
                    totalCosts.value[props.id] = res.data.costDetails;
                    contracts.value = [];
                    const activeContractIds = [];
                    expandedIdx.value = -1;
                    for (let i = 0; i < res.data.contracts.length; i += 1) {
                        totalCosts.value[res.data.contracts[i].id] = res.data.contracts[i].costDetails;
                        const contract = prepareContract(res.data.contracts[i]);
                        contracts.value.push(contract);
                        if (contract.status === ContractStatus.Active) {
                            activeContractIds.push(contract.id);
                        } else if (contract.status === ContractStatus.Signed) {
                            signedContracts.value.push(contract.id);
                        }
                    }
                    allContractsSigned.value = contracts.value.every(
                        (c: Contract) => c.status === ContractStatus.Signed,
                    );
                    allProvidersHaveAnEthaddress.value = contracts.value.every(
                        (c: Contract) => !!c.providerOrg.ethaddress,
                    );
                    if (activeContractIds.length > 0) {
                        exec(ContractsAPI.getPendingUpdatingContracts(activeContractIds.join(','))).then(
                            (response: any) => {
                                pendingUpdatingContracts.value = response.data;
                            },
                        );
                    }
                    if (!multiple.value) expandedIdx.value = 0;
                })
                .catch((e) => {
                    (root as any).$toastr.e(e, 'Error');
                    back();
                });
        };

        const cancel = () => {
            if (isNewContract.value) {
                root.$router.push({ name: 'contracts' });
            } else {
                back();
            }
        };

        const toggleExpand = (idx: number) => {
            expandedIdx.value = expandedIdx.value === idx ? -1 : idx;
        };

        const { removeAssetFromWishList } = useWishList(root);

        const requestsSent = () => {
            currentStep.value = 1;
            isNewContract.value = false;
            for (let i = 0; i < contracts.value.length; i++) {
                if (contracts.value[i].assetId) {
                    removeAssetFromWishList(contracts.value[i].assetId as number);
                }
            }
            if (multiple.value) clearCart();
            else removeFromCart(Number(props.id));
        };

        const scrollUp = () => {
            contentRef.value.scrollTo({ top: 0, behavior: 'smooth' });
        };

        const setContractAction = (value: any) => {
            contractAction.value = value;
            if (contractAction.value) {
                if ([ContractAction.Extend, ContractAction.Reactivate].includes(contractAction.value)) {
                    creatingContract.value = true;
                    isNewContract.value = true;
                }
            } else {
                creatingContract.value = false;
                isNewContract.value = false;
            }
        };

        const updateContracts = (newContracts: any) => {
            contracts.value = newContracts;
            signedContracts.value = [];
            allContractsSigned.value = contracts.value.every((c: any) => c.status === ContractStatus.Signed);
            for (let i = 0; i < newContracts.length; i += 1) {
                if (newContracts[i].status === ContractStatus.Signed) {
                    signedContracts.value.push(newContracts[i].id);
                }
                totalCosts.value[newContracts[i].id] = newContracts[i].costDetails;
            }
        };

        const checkSignedContracts = (id: string) => {
            signedContracts.value.push(id);
            allContractsSigned.value = contracts.value.length === signedContracts.value.length;

            // If all contracts are signed set the bundle's total cost details
            if (allContractsSigned.value && bundle.value) {
                const contractsIds = Object.keys(totalCosts.value).filter((uid: string) => uid !== bundle.value?.id);
                const costDetails = {
                    totalCost: 0,
                    commissionFeePercentage: contractsIds.length
                        ? totalCosts.value[contractsIds[0]].commissionFeePercentage
                        : 0,
                    commissionFee: 0,
                    totalCostWithCommissionFee: 0,
                };
                for (let i = 0; i < contractsIds.length; i++) {
                    const contractCostDetails = totalCosts.value[contractsIds[i]];
                    costDetails.totalCost += contractCostDetails.totalCost;
                    costDetails.commissionFee += contractCostDetails.commissionFee;
                    costDetails.totalCostWithCommissionFee += contractCostDetails.totalCostWithCommissionFee;
                }
                totalCosts.value[bundle.value.id] = costDetails;
            }
        };

        const sync = () => {
            if (!bundle.value) return;
            exec(ContractsAPI.syncContract(bundle.value.id, 'bundle'))
                .then((res: any) => {
                    const newBundle = res.data;
                    if (!R.isNil(newBundle.pendingTransactionId))
                        (root as any).$toastr.w(
                            'Blockchain transaction is still pending. Please try again later.',
                            'Pending Transaction',
                        );
                    else {
                        (root as any).$toastr.s('Bundle state synchronised!', 'Success');
                        getBundle();
                    }
                })
                .catch(() => {
                    (root as any).$toastr.e('Failed to synchronise bundle state!', 'Error');
                });
        };

        const rejectBundle = () => {
            for (let i = 0; i < contracts.value.length; i++)
                if (
                    contracts.value[i].status !== ContractStatus.Declined &&
                    contracts.value[i].status !== ContractStatus.Rejected
                )
                    contracts.value[i].status = ContractStatus.Withdrawn;
        };

        if (isBlockchainEnabled.value && (!ethNetwork.value || !hasWallet.value)) {
            root.$router.push({ name: 'contracts' });
        }

        // New Contract or Bundle
        if (isNewContract.value) {
            // Get ids of assets that need to be included in a new contract
            const assetIds =
                props.id === 'bundle' || props.id === 'separate'
                    ? cart.value.map((cartAsset: any) => cartAsset.id).join(',')
                    : props.id;

            exec(AssetsAPI.getListOfAssets(assetIds))
                .then((res: any) => {
                    const returnedAssetIds = res.data.map((item: any) => item.id);
                    for (let i = 0; i < assetIds.split(',').length; i++) {
                        const assetId = Number(assetIds.split(',')[i]);
                        if (!returnedAssetIds.includes(assetId)) removeFromCart(assetId);
                    }
                    if (!res.data.length) {
                        (root as any).$toastr.e('You no longer have access to these assets', 'Error');
                        root.$router.push({ name: 'contracts' });
                    }
                    for (let i = 0; i < res.data.length; i++) {
                        const contract = R.clone(emptyContract.value);
                        contract.asset = res.data[i];
                        contract.assetId = res.data[i].id;

                        if (contract.asset?.organisation) {
                            contract.providerOrgId = contract.asset.createdBy.organisationId;
                            contract.providerOrg.businessName = contract.asset.organisation.businessName;
                            contract.providerOrg.ethaddress = contract.asset.organisation.ethaddress;
                        }
                        // If the asset type is other than 'Dataset', then the payment must be made within the platform (using ethers).
                        if (isBundle.value || contract.asset?.assetTypeId !== 1) {
                            contract.metadata.pricing.reimbursementMethod = ReimbursementMethod.Online;
                            contract.metadata.pricing.currency = Currency.Ether;
                        }
                        contracts.value.push(contract);
                    }
                    allProvidersHaveAnEthaddress.value = contracts.value.every((c: any) => !!c.providerOrg.ethaddress);
                    if (!multiple.value) expandedIdx.value = 0;
                    isConsumer.value = true;
                })
                .catch((e) => {
                    (root as any).$toastr.e(e, 'Error');
                    root.$router.push({ name: 'contracts' });
                });
        }
        // Bundle
        else if (isBundle.value) {
            getBundle();
        }
        // Single Contract
        else {
            exec(ContractsAPI.get(props.id))
                .then((res: any) => {
                    if (res.data.bundleId && !bundle.value) {
                        (root as any).$toastr.e('This action is forbidden', 'Error');
                        root.$router.push({ name: 'contracts' });
                    }
                    totalCosts.value[props.id] = res.data.costDetails;
                    const contract = prepareContract(res.data);
                    expandedIdx.value = 0;
                    isConsumer.value = res.data.consumerOrgId === user.value.organisationId;
                    contracts.value.push(contract);
                    if (contract.status === ContractStatus.Active) {
                        exec(ContractsAPI.getPendingUpdatingContracts(`${props.id}`)).then((response: any) => {
                            pendingUpdatingContracts.value = response.data;
                        });
                    }
                })
                .catch((e) => {
                    (root as any).$toastr.e(e, 'Error');
                    back();
                });
        }

        exec(ContractsAPI.getActivePending()).then((res: any) => {
            activePendingContracts.value = res.data;
        });

        return {
            cart,
            contentRef,
            expandedIdx,
            contracts,
            bundle,
            totalCosts,
            currentStep,
            pendingUpdatingContracts,
            loading,
            creatingContract,
            isNewContract,
            isBundle,
            isConsumer,
            isLegalRepresentative,
            contractAction,
            isBlockchainEnabled,
            multiple,
            allProvidersHaveAnEthaddress,
            configurationErrors,
            pageTitle,
            activePendingContracts,
            allContractsSigned,
            back,
            getBundle,
            updateContracts,
            cancel,
            toggleExpand,
            requestsSent,
            scrollUp,
            setContractAction,
            sync,
            checkSignedContracts,
            rejectBundle,
        };
    },
});
