import { AxiosRequestConfig } from 'axios';
import { AssetFacetedQuery, AssetQuery } from '../types';

const endpoint = '/api/search';

export default {
    search: (data: AssetQuery) => ({ method: 'POST', url: `${endpoint}`, data } as AxiosRequestConfig),
    myAssetsFacetedSearch: (data: AssetFacetedQuery) =>
        ({ method: 'POST', url: `${endpoint}/assets/mine`, data } as AxiosRequestConfig),
    acquiredAssetsSearch: (data: AssetFacetedQuery) =>
        ({ method: 'POST', url: `${endpoint}/assets/acquired`, data } as AxiosRequestConfig),
    assetsSearchRetrieveAsFile: (data: AssetFacetedQuery) =>
        ({ method: 'POST', url: `${endpoint}/assets/retrieve-as-file`, data } as AxiosRequestConfig),
    assetsSearchRetrieveViaAPI: (data: AssetFacetedQuery) =>
        ({ method: 'POST', url: `${endpoint}/assets/retrieve-via-api`, data } as AxiosRequestConfig),
    assetsSearchUseInCloud: (data: AssetFacetedQuery) =>
        ({ method: 'POST', url: `${endpoint}/assets/use-in-cloud`, data } as AxiosRequestConfig),
    assetsSearchUseOnPremise: (data: AssetFacetedQuery) =>
        ({ method: 'POST', url: `${endpoint}/assets/use-on-premise`, data } as AxiosRequestConfig),
    assetsSearchDownloadDirectly: (data: AssetFacetedQuery) =>
        ({ method: 'POST', url: `${endpoint}/assets/download-directly`, data } as AxiosRequestConfig),
    myJobsSearch: (data: any) => ({ method: 'POST', url: `${endpoint}/jobs/mine`, data } as AxiosRequestConfig),
    myWorkflowsSearch: (data: any) =>
        ({ method: 'POST', url: `${endpoint}/workflows/mine`, data } as AxiosRequestConfig),
    sharedJobsSearch: (data: any) => ({ method: 'POST', url: `${endpoint}/jobs`, data } as AxiosRequestConfig),
    sharedWorkflowsSearch: (data: any) =>
        ({ method: 'POST', url: `${endpoint}/workflows`, data } as AxiosRequestConfig),
    preview: (id: number, data: object) => ({ method: 'POST', url: `${endpoint}/preview`, data } as AxiosRequestConfig),
    retrievalQueries: (data: any) =>
        ({ method: 'POST', url: `${endpoint}/retrieval-queries`, data } as AxiosRequestConfig),
    contracts: (data: any) => ({ method: 'POST', url: `${endpoint}/contracts`, data } as AxiosRequestConfig),
    marketplace: (data: any) => ({ method: 'POST', url: `${endpoint}/marketplace`, data } as AxiosRequestConfig),
};
