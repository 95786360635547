
















































import { SortedSearch, SvgImage } from '@/app/components';
import store from '@/app/store';
import { WishListAPI } from '@/modules/asset/api';
import { ContractsAPI } from '@/modules/sharing/api';
import { AssetCard, AssetDetails } from '@/modules/sharing/components';
import { useAxios } from '@/app/composable';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import * as R from 'ramda';

export default defineComponent({
    name: 'WishList',
    metaInfo: {
        title: 'Wish List',
    },
    components: { AssetCard, AssetDetails, OrbitSpinner, SortedSearch, SvgImage },
    setup(__, { root }) {
        const sortOptions = [
            { key: 'name', label: 'Name' },
            { key: 'assetTypeId', label: 'Asset Type' },
            { key: 'createdAt', label: 'Date added' },
        ];

        const selectedAsset = ref<any>(null);
        const wishList = ref<any>([]);
        const activePendingContracts = ref<any[]>([]);

        const { exec, loading } = useAxios(true);

        const search = ref<any>({ text: '', sortBy: 'createdAt', sortOrder: 'desc' });

        exec(ContractsAPI.getActivePending()).then((res: any) => {
            activePendingContracts.value = res.data;
        });

        // loading all wish list assets
        const loadWishListAssets = async () => {
            exec(WishListAPI.getWishList())
                .then((res: any) => {
                    const response: { assetId: number }[] = res.data;
                    const assetsIds = R.pluck('assetId', response);
                    store.commit.sharing.SET_WISH_LIST_ASSETS_IDS(assetsIds);
                    wishList.value = response;
                })
                .catch(() => {
                    (root as any).$toastr.e('Error occured while fetching Wish List', 'Error');
                });
        };

        loadWishListAssets();

        const filteredWishList = computed<Record<string, any>[]>(() => {
            if (wishList.value.length === 0) return [];

            if (search.value.text === '') return wishList.value;

            if (!wishList.value.some((wl: any) => wl.assetId === selectedAsset.value?.id)) {
                selectedAsset.value = null;
            }

            return wishList.value.filter((result: any) =>
                R.toLower(result.asset.name).includes(R.toLower(search.value.text)),
            );
        });

        const sortedWishList = computed<Record<string, any>[]>(() => {
            if (!filteredWishList.value) return [];

            const sortProp =
                search.value.sortBy === 'createdAt'
                    ? R.prop(search.value.sortBy)
                    : R.path(['asset', search.value.sortBy]);

            const orderBy = search.value.sortOrder === 'desc' ? R.descend(sortProp) : R.ascend(sortProp);
            return R.sortWith([orderBy], filteredWishList.value);
        });

        const results = computed(() =>
            sortedWishList.value.filter((item: any) => store.state.sharing.wishListAssetsIds.includes(item.assetId)),
        );

        const handleEscape = (e: KeyboardEvent) => {
            if (e.key === 'Esc' || e.key === 'Escape') {
                selectedAsset.value = null;
            }
        };

        document.addEventListener('keydown', handleEscape);
        root.$once('hook:beforeDestroy', () => {
            document.removeEventListener('keydown', handleEscape);
        });

        return {
            wishList,
            results,
            loading,
            selectedAsset,
            activePendingContracts,
            handleEscape,
            sortOptions,
            search,
        };
    },
});
