































































import { defineComponent, ref } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import { useAxios } from '@/app/composable';
import { Bundle, Contract } from '../types';
import { ContractsAPI } from '../api';
import { ContractPreview, ContractHistory, BundlePreview } from '../components';
import { ChevronLeftIcon } from '@vue-hero-icons/outline';
import { useContractStatus } from '../composable';

export default defineComponent({
    name: 'ContractMonitoring',
    components: {
        OrbitSpinner,
        ContractPreview,
        ChevronLeftIcon,
        ContractHistory,
        BundlePreview,
    },
    props: {
        type: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
    },
    setup(props, { root }) {
        const { exec, loading } = useAxios(true);
        const { statusClass } = useContractStatus();
        const contract = ref<Contract | null>(null);
        const bundle = ref<Bundle | null>(null);
        const selectedContractId = ref<string>(props.id);
        const selectedContract = ref<Bundle | Contract | null>(null);
        const bundleSelected = ref<boolean>(false);

        const getContract = () => {
            if (props.type === 'bundle') {
                exec(ContractsAPI.getBundle(props.id)).then((res: any) => {
                    bundle.value = res.data;
                    selectedContract.value = res.data;
                    bundleSelected.value = true;
                });
            } else {
                exec(ContractsAPI.get(props.id)).then((res: any) => {
                    contract.value = res.data;
                    selectedContract.value = res.data;
                });
            }
        };

        getContract();

        const changeContract = () => {
            if (bundle.value?.id === selectedContractId.value) {
                selectedContract.value = bundle.value;
                bundleSelected.value = true;
            } else {
                selectedContract.value =
                    bundle.value?.contracts.find(
                        (bundleContract: Contract) => bundleContract.id === selectedContractId.value,
                    ) ?? null;
                bundleSelected.value = false;
            }
        };

        const back = () => {
            if (window.history.length > 0) root.$router.go(-1);
            else root.$router.push({ name: 'contracts' });
        };

        return {
            selectedContractId,
            selectedContract,
            loading,
            contract,
            bundle,
            bundleSelected,
            statusClass,
            changeContract,
            back,
        };
    },
});
